import "core-js/modules/es.array.push.js";
import { reactive, toRefs, getCurrentInstance, watch } from "vue";
const __default__ = {
  props: {
    disabled: {
      default: false
    }
  },
  setup(props, content) {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      uploadPercent: "0",
      preUrl: "",
      preName: "",
      isPre: false,
      headers: {
        authKey: localStorage.getItem("authKey")
      },
      baseURL: process.env.VUE_APP_BASE_URL,
      fileList: proxy.$attrs['file-list'] || [],
      dir: proxy.$attrs['dir'] || 'client'
    });
    watch(() => proxy.$attrs['file-list'], val => {
      state.fileList = proxy.$attrs['file-list'];
    }, {
      deep: true
    });
    const handleRemove = file => {
      const uploadFiles = state.fileList;
      state.fileList = [];
      uploadFiles.map(item => {
        if (item.uid != file.uid) {
          state.fileList.push({
            ...item
          });
        }
      });
      content.emit("removeSuccess", state.fileList);
    };
    const handlePreview = file => {
      state.preName = file.name;
      // 上传时预览和上传后预览的数据处理
      if (file.response) {
        state.preUrl = file.response.data.ossSrc || file.url;
      } else {
        state.preUrl = file.request_url || file.url || file.src;
      }
      // 图片和文件处理
      if (proxy.$attrs.listType === "picture") {
        state.isPre = true;
      } else {
        proxy.$downloadFile(state.preUrl, file.name);
      }
    };
    const handleProgress = file => {
      state.uploadPercent = parseInt(file.percent) - 10 + '%';
      content.emit("isUploading", true);
    };
    const handleSuccess = (res, uploadFile, uploadFiles) => {
      if (res.code == 0) {
        state.fileList.push({
          name: uploadFile.name,
          src: res.data.src,
          uid: uploadFile.uid,
          url: res.data.ossSrc
        });
        console.log(state.fileList);
      } else {
        let index = uploadFiles.findIndex(item => item.uid == uploadFile.uid);
        uploadFiles.splice(index, 1);
        proxy.$TcMsg({
          type: "error",
          text: res.error
        });
      }
      content.emit("uploadSuccess", state.fileList);
      content.emit("isUploading", false);
    };
    const downloadPic = () => {
      proxy.$downloadFile(state.preUrl, state.preName);
    };

    // 上传的文件超出limit
    const handleExceed = (files, uploadFiles) => {
      proxy.$TcMsg({
        type: "error",
        text: '仅支持上传' + proxy.$attrs.limit + '个文件',
        time: 1500
      });
    };
    return {
      ...toRefs(state),
      handlePreview,
      handleRemove,
      downloadPic,
      handleProgress,
      handleSuccess,
      handleExceed
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "0263f91a": _ctx.uploadPercent
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;